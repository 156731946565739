<template>
  <div class="Massembly">
    <div class="MvideoBox">
      <div class="model" :class="load ? 'blackModel' : ''"></div>
      <div class="title">{{ data.title }}</div>
      <div class="title2">{{ data.describe }}</div>
      <video
        :src="data.url"
        ref="video"
        autoplay
        loop
        muted
        webkit-playsinline="true"
        playsinline="true"
      ></video>
    </div>
    <div class="info">
      <div class="box1">
        <img src="./../../assets/img/mun.png" alt="" class="img1" />
      </div>
      <div class="type-name">BetterWorld全球青少年模拟联合大会</div>
      <div class="type-name2">BetterWorld Global Youth</div>
      <div class="type-name3">Model United Nations Conference</div>
      <div class="type-name4 color">2025年7月29日-8月1日，上海</div>
    </div>
    <div class="countDown">
      <div class="labelBox">
        <div class="countLabel">天</div>
        <div class="leftLeaf firstDay">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.hundred ? "1" : "0" }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.hundred ? "1" : "0" }}</span>
          </div>
          <div class="rotorLeaf">
            <div class="rotate">
              {{ countDownTime.hundred ? "1" : "0" }}
            </div>
            <div class="rotate">
              {{ countDownTime.hundred ? "1" : "0" }}
            </div>
          </div>
        </div>
        <div class="leftLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.day1 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.dayCopy1 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.dayType1 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.day1 }}
            </div>
            <div class="rotate">
              {{ countDownTime.dayCopy1 }}
            </div>
          </div>
        </div>
        <div class="rightLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.day2 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.dayCopy2 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.dayType2 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.day2 }}
            </div>
            <div class="rotate">
              {{ countDownTime.dayCopy2 }}
            </div>
          </div>
        </div>
      </div>
      <div class="labelBox">
        <div class="countLabel">时</div>
        <div class="leftLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.hour1 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.hourCopy1 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.hourType1 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.hour1 }}
            </div>
            <div class="rotate">
              {{ countDownTime.hourCopy1 }}
            </div>
          </div>
        </div>
        <div class="rightLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.hour2 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.hourCopy2 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.hourType2 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.hour2 }}
            </div>
            <div class="rotate">
              {{ countDownTime.hourCopy2 }}
            </div>
          </div>
        </div>
      </div>
      <div class="labelBox">
        <div class="countLabel">分</div>
        <div class="circleBox">
          <div class="timeCut"></div>
          <div class="timeCut"></div>
        </div>
        <div class="leftLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.min1 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.minCopy1 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.minType1 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.min1 }}
            </div>
            <div class="rotate">
              {{ countDownTime.minCopy1 }}
            </div>
          </div>
        </div>
        <div class="rightLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.min2 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.minCopy2 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.minType2 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.min2 }}
            </div>
            <div class="rotate">
              {{ countDownTime.minCopy2 }}
            </div>
          </div>
        </div>
      </div>
      <div class="labelBox">
        <div class="countLabel">秒</div>
        <div class="circleBox">
          <div class="timeCut"></div>
          <div class="timeCut"></div>
        </div>
        <div class="leftLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.sec1 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.secCopy1 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.secType1 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.sec1 }}
            </div>
            <div class="rotate">
              {{ countDownTime.secCopy1 }}
            </div>
          </div>
        </div>
        <div class="rightLeaf">
          <div class="countDownline"></div>
          <div class="leaf leafTop">
            <span>{{ countDownTime.sec2 }}</span>
          </div>
          <div class="leaf leafBottom">
            <span>{{ countDownTime.secCopy2 }}</span>
          </div>
          <div
            class="rotorLeaf"
            :class="countDownTime.secType2 ? 'flipped' : ''"
          >
            <div class="rotate">
              {{ countDownTime.sec2 }}
            </div>
            <div class="rotate">
              {{ countDownTime.secCopy2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="theme">
      <div class="title">
        <div>联合国秘书长</div>
        <span><span class="color">安东尼奥·古特雷斯</span>先生致辞 </span>
      </div>
      <div class="content">
        <video
          class="contentVideo"
          :src="theme.url"
          :poster="theme.cover"
          controls
          controlslist="nodownload"
          disablePictureInPicture
          @play="play"
          @ended="videoEnd"
        ></video>
      </div>
    </div>
    <div class="theme theme2">
      <div class="title">
        <span class="color">BetterWorldMUN</span>
        <div>主题回顾</div>
      </div>
      <div class="content">
        <video
          class="contentVideo contentVideo1"
          :src="theme1.url"
          :poster="theme1.cover"
          controls
          controlslist="nodownload"
          disablePictureInPicture
          @play="play"
          @ended="videoEnd1"
        ></video>
      </div>
    </div>
    <div class="theme theme2">
      <div class="title">
        <span class="color">BetterWorldMUN</span>
        <div>大会回顾</div>
      </div>
      <div class="content">
        <video
          class="contentVideo contentVideo2"
          :src="theme2.url"
          :poster="theme2.cover"
          controls
          controlslist="nodownload"
          disablePictureInPicture
          @play="play"
          @ended="videoEnd2"
        ></video>
      </div>
    </div>
    <div class="letterinfo">
      <div class="infoHead">
        <span class="text1">BetterWorldMUN</span>
        <span class="text2">全球青少年模拟联合国大会</span>
        <span class="text3">组委会致信</span>
        <img src="./../../assets/img/letter.png" alt="" />
      </div>
      <div class="textBoxContent">
        <div class="textContent">
          <div
            class="desc"
            v-for="(item, index) in configData.text"
            :key="index"
          >
            <img
              src="./../../assets/img/letterLeft.png"
              alt=""
              v-if="index == 1"
              class="letterLeft"
            />
            <div :class="index == 1 ? 'letterLeftSpan' : ''">
              {{ item.desc }}
            </div>
          </div>
        </div>
        <div class="letterBottom">
          <div class="Bottomname">
            <span>BetterWorldMUN</span>
            <div class="line"></div>
          </div>
          <div class="letterRight">
            <img src="./../../assets/img/letterRight.png" alt="" />
            <div class="line"></div>
          </div>
        </div>
      </div>

      <div class="name">
        <span class="name1">{{ configData.textName.name1 }}</span>
        <span>{{ configData.textName.name2 }}</span>
        <span>{{ configData.textName.name3 }}</span>
        <span>{{ configData.textName.name4 }}</span>
      </div>
    </div>

    <div class="speaker">
      <div class="titleName">
        <span>精彩瞬间</span>
      </div>
      <div class="wrapper" @touchstart="touchstartFn" @touchend="touchendFn">
        <div class="dots">
          <div
            :class="Current1 == index ? 'activeDots dot' : 'dot'"
            v-for="(item, index) in talker"
            :key="index"
          ></div>
        </div>
        <el-carousel
          @change="change1"
          ref="carousel"
          class="carousel"
          :interval="5000"
          arrow="never"
          indicator-position="none"
        >
          <el-carousel-item v-for="(item, index) in talker" :key="index">
            <div class="speakerBox">
              <img :src="item.avatar" alt="" />
              <!-- <div class="title">{{ item.tag }}</div>
                            <div class="info">{{ item.profile }}</div> -->
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="list">
        <img :src="talkerImg" alt="" />
      </div>

      <!-- <div class="schedule">
        <div class="title">
          <span>重要日期</span>
        </div>
        <div class="content">
          <div class="left">
            <div class="timeBox">
              <div class="time">
                <div
                  class="timelist"
                  v-for="(item, index) in step"
                  :key="index"
                >
                  <div class="lineCut" v-if="index != 0"></div>
                  <div class="listBox">
                    <div class="clock">
                      <img src="./../../assets/img/clock.png" alt="" />
                      <span>{{ item.time }}</span>
                    </div>
                    <div class="stepName">{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="place">
        <div class="title">
          <span>活动地址</span>
        </div>
        <div
          class="mapContent"
          @touchstart="touchstartFn3"
          @touchend="touchendFn3"
        >
          <div class="dots">
            <div
              :class="Current2 == index ? 'activeDots dot' : 'dot'"
              v-for="(item, index) in mapList"
              :key="index"
            ></div>
          </div>
          <el-carousel
            ref="carouselMap"
            @change="change2"
            class="carouselMap"
            :interval="5000"
            arrow="never"
            indicator-position="none"
          >
            <el-carousel-item v-for="(item, index) in mapList" :key="index">
              <div class="content">
                <img :src="item" alt="" class="img" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="placeTip">
          <img src="./../../assets/img/placeTip.png" alt="" />
          <span>上海</span>
        </div>
      </div>
    </div>

    <!-- 会议费用 -->
    <div class="money">
      <div class="title">会议费用</div>
      <div class="moneyBox">
        <div class="name">
          <span>BetterWorldMUN </span>
          <span class="year">全球青少年模拟联合国大会2025夏季会议</span>
        </div>
        <div class="moneyContent">
          <div class="count">
            <div class="number">
              <span class="moneyNum">¥</span>{{ CommitteeList.price }}
            </div>
          </div>
          <div class="desc">
            （包含会议费，会议材料费，个人保险费，政府税费）
          </div>
          <div class="leftinfo">
            <div>参会证明：</div>
            <span
              >所有的参会代表都将获得由BetterWorld全球青少年模拟联合国大会组委会颁发的</span
            >
            <span class="color">参会证明</span>。
            <div class="secondLine">
              <span
                >表现优异者，还有机会获得BetterWorld全球青少年模拟联合国大会</span
              >
              <span class="color">获奖证书</span>
              <span>，以及</span>
              <span class="color">特别奖项</span>。
            </div>
          </div>
          <div class="backdesc">
            <div>退费政策：</div>
            <span>2025年6月1日23:59之前，可申请退款（不包含手续费）；</span>
              <span>2025年6月15日23:59之前，可退50%；</span>
              <span>2025年6月15日00:00开始，不可退款；</span>
            <div class="backTips">
              <div>
                <span class="color">*</span>
                <span
                  >请注意，如果选择退费，付费过程中已产生的手续费，需用户承担。</span
                >
              </div>
              <div>
                <span class="color">*</span>
                <span>手续费为已付费总金额的2%。</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="timeBox">
                <span>早鸟价：</span>
                <div class="timeWrapper">
                    <div class="timeNum">
                        {{eb_price_time.day}}
                    </div>
                    <span>天</span>
                </div>
                <div class="timeWrapper">
                    <div class="timeNum">
                        {{eb_price_time.hour}}
                    </div>
                    <span>时</span>
                </div>
                <div class="timeWrapper">
                    <div class="timeNum">
                        {{eb_price_time.min}}
                    </div>
                    <span>分</span>
                </div>
                <div class="timeWrapper">
                    <div class="timeNum">
                        {{eb_price_time.sec}}
                    </div>
                    <span>秒</span>
                </div>
            </div> -->
      </div>
    </div>
    <!-- 会议住宿 -->
    <!-- <div class="live">
      <div class="title">会议住宿</div>
      <div class="liveContent">
        <div class="liveBox">
          <img
            src="https://www.betterworldmun.com/uploads/wxapp/img/live.png"
            alt=""
            class="liveImg"
          />
          <div class="liveTitle">{{ HotelList.name }}</div>
          <div class="payNum">
            <span class="moneyNum">¥</span>{{ HotelList.price }}
          </div>
          <div class="tips">
            <div
              class="tipBox"
              v-for="(item, index) in HotelList.introduceList"
              :key="index"
            >
              <div class="circle"></div>
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
        <div class="livedesc">
          <div class="descTitle">
            <span class="color">* </span>
            <span>注意事项：</span>
          </div>
          <div class="label">
            <div class="sort">（1）</div>
            <span
              >小学生优先安排大床房，初中生优先安排双床或大床房，高中生和大学生优先安排双床房。</span
            >
          </div>
          <div class="label">
            <div class="sort">（2）</div>
            <span
              >注意此费用包含半间房的住宿使用权，以及包含一人份的自助早餐、午餐工作简餐、晚餐工作简餐；如若独享一整间，请在报名时将数量调整为2。</span
            >
          </div>
          <div class="label">
            <div class="sort">（3）</div>
            <span
              >如果你有想要同住的舍友，请备注清楚。房间数量有限，先订先得。</span
            >
          </div>
        </div>
        <div class="livetimeBox">
          <span class="day">2025年1月18日</span>
          <div class="countDate">
            <div class="line line1"></div>
            <span>3晚</span>
            <div class="line line2"></div>
          </div>
          <span class="day">2025年1月21日</span>
        </div>
      </div>
    </div> -->
    <!-- 注册指引 -->
    <div class="register">
      <div class="title">注册指引</div>
      <div class="stepBox">
        <div class="stepItem">
          <div class="steptitle">第<span class="color">一</span>步:</div>
          <div class="stepinfo">填写报名</div>
        </div>
        <div class="stepItem">
          <div class="steptitle">第<span class="color">二</span>步:</div>
          <div class="stepinfo">完成缴费</div>
        </div>
        <div class="stepItem">
          <div class="steptitle">第<span class="color">三</span>步:</div>
          <div class="stepinfo">添加组委会微信</div>
        </div>
      </div>
    </div>
    <div class="joinContent">
      	<div class="title">注册报名</div>
		  <div class="stepWrapper">
				<div class="name">注册步骤：</div>
				<div class="stepList">
						<div class="lineListBox">
							<img src="./../../assets/img/clock.png" class="clockImg"/>
							<span>微信扫描下方二维码</span>
						</div>
				</div>
				<div class="stepList">
					<div class="lineCut"></div>
						<div class="lineListBox">
							<img src="./../../assets/img/clock.png" class="clockImg"/>
							<span>小程序点击报名按钮</span>
						</div>
				</div>
				<div class="stepList">
					<div class="lineCut"></div>
						<div class="lineListBox">
							<img src="./../../assets/img/clock.png" class="clockImg"/>
							<span>请准确填写报名表格</span>
						</div>
				</div>
				<div class="stepList">
					<div class="lineCut"></div>
						<div class="lineListBox">
							<img src="./../../assets/img/clock.png" class="clockImg"/>
							<span>支付成功即注册报名</span>
						</div>
				</div>
			</div>
			<div class="codeBox">
				<img src="./../../assets/img/Mvxcode.png" class="Mvxcode"/>
			</div>
    </div>
    <!-- <div class="parterTitle">合作伙伴</div>
    <div class="partner">
      <div class="title">战略合作伙伴</div>
      <div
        class="partnerBox"
        @touchstart="touchstartFn2"
        @touchend="touchendFn2"
      >
        <div class="dots">
          <div
            :class="Current2 == index ? 'activeDots dot' : 'dot'"
            v-for="(item, index) in parterList"
            :key="index"
          ></div>
        </div>
        <el-carousel
          @change="change2"
          ref="carouselParter2"
          class="carousel"
          :interval="5000"
          arrow="never"
          indicator-position="none"
        >
          <el-carousel-item v-for="(item, index) in parterList" :key="index">
            <div class="partnerItem">
              <img :src="item" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="title">重要合作伙伴</div>
      <div
        class="partnerBox partnerBox5"
        @touchstart="touchstartFn5"
        @touchend="touchendFn5"
      >
        <div class="dots">
          <div
            :class="Current5 == index ? 'activeDots dot' : 'dot'"
            v-for="(item, index) in important_partner_mini"
            :key="index"
          ></div>
        </div>
        <el-carousel
          @change="change5"
          ref="carouselParter5"
          class="carousel"
          :interval="5000"
          arrow="never"
          indicator-position="none"
        >
          <el-carousel-item
            v-for="(item, index) in important_partner_mini"
            :key="index"
          >
            <div class="partnerItem">
              <img :src="item" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="title">合作伙伴</div>
      <div
        class="partnerBox"
        @touchstart="touchstartFn4"
        @touchend="touchendFn4"
      >
        <div class="dots">
          <div
            :class="Current4 == index ? 'activeDots dot' : 'dot'"
            v-for="(item, index) in parter"
            :key="index"
          ></div>
        </div>
        <el-carousel
          ref="carouselParter4"
          @change="change4"
          class="carousel"
          :interval="5000"
          arrow="never"
          indicator-position="none"
        >
          <el-carousel-item v-for="(item, index) in parter" :key="index">
            <div class="partnerItem">
              <img :src="item" alt="" class="img" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  banner,
  video,
  presidium,
  partner,
  hotel,
  committee,
} from "./../../api/api";
import { config } from "./../../utils/config";
export default {
  data() {
    return {
		configData: {
			textName : {}
		},
      height: "252",
      data: {
        title: "",
        describe: "",
        video_url: "",
      },
      load: false,
      startMove: 0,
      startMove2: 0,
      startMove3: 0,
      startMove4: 0,
      startMove5: 0,
      Current1: 0,
      Current2: 0,
      Current3: 0,
      Current4: 0,
      Current5: 0,
      talkerImg: "",
      mapList: [],
      parterList: [],
      parter: [],
      talker: [],
      important_partner_mini: [],
      theme: {},
      theme1: {},
      theme2: {},
      step: [{
                time: '2024年3月3日',
                name: '常规报名开始',
            },
            {
                time: '2024年5月1日',
                name: '组委会、议题公布',
            },
            {
                time: '2024年7月1日',
                name: '常规报名截至',
            },
            {
                time: '2024年7月2日',
                name: '候补报名开始',
            },
            {
                time: '2024年7月15日',
                name: '候补报名截止',
            },
            {
                time: '2024年7月30日',
                name: '会议开幕',
            },
            {
                time: '2024年8月2日',
                name: '会议闭幕',
            },
        ],
      countDownTime: {
        time: "612",
        day1: "0",
        day2: "0",
        hour1: "0",
        hour2: "0",
        min1: "0",
        min2: "0",
        sec1: "0",
        sec2: "0",
        secCopy1: "0",
        secCopy2: "0",
        dayCopy1: "0",
        dayCopy2: "0",
        hourCopy1: "0",
        hourCopy2: "0",
        minCopy1: "0",
        minCopy2: "0",
        dayType1: false,
        hourType1: false,
        minType1: false,
        secType1: false,
        dayType2: false,
        hourType2: false,
        minType2: false,
        secType2: false,
        hundred: false,
      },
      eb_price_time: {
        day: "00",
        hour: "00",
        min: "00",
        sec: "00",
        time: 0,
      }, //早鸟价倒计时
      CommitteeList: {},
      HotelList: {},
    };
  },
  mounted() {
	if (this.$store.state.language.type == 1) {
      this.configData = config.assembly.chinese;
    } else {
      this.configData = config.assembly.english;
    }
    this.getCommitteeList();
    this.getHotelList();
    this.talkerImg = JSON.parse(localStorage.config).talker_mini.value[0];
    this.mapList = JSON.parse(localStorage.config).activity_address.value;
    banner({ seat: 4,'device': 2 }).then((res) => {
      var timeDefer =
        (JSON.parse(localStorage.config).activity_date.value -
          res.data.time * 1000) /
        1000;
      if (timeDefer > 0) {
        this.countDownTime.time = timeDefer;
        setInterval(() => {
          this.countDownTimeFn();
        }, 1000);
      }
      var eb_price_Defer =
        (JSON.parse(localStorage.config).eb_price_time.value -
          res.data.time * 1000) /
        1000;
      if (eb_price_Defer > 0) {
        this.eb_price_time.time = eb_price_Defer;
        setInterval(() => {
          this.ebPriceDeferFn();
        }, 1000);
      }
      if (res.data.code === "10000") {
        this.data = res.data.data[0];
        setTimeout(() => {
          this.load = true;
        }, 800);
      } else {
        this.$message.error(res.data.msg);
      }
    });
    presidium().then((res) => {
      if (res.data.code === "10000") {
        this.talker = res.data.data;
      } else {
        this.$message.error(res.data.msg);
      }
    });
    partner({ device: 2 }).then((res) => {
      if (res.data.code === "10000") {
        this.parter = res.data.data["1"];
        this.parterList = res.data.data["2"];
        this.important_partner_mini = res.data.data["3"];
      } else {
        this.$message.error(res.data.msg);
      }
    });
    //大会主题
    video({ type: 1 }).then((res) => {
      if (res.data.code === "10000") {
        this.theme = res.data.data[0];
      } else {
        this.$message.error(res.data.msg);
      }
    });
    video({ type: 4 }).then((res) => {
      if (res.data.code === "10000") {
        this.theme1 = res.data.data[0];
      } else {
        this.$message.error(res.data.msg);
      }
    });
    video({ type: 5 }).then((res) => {
      if (res.data.code === "10000") {
        this.theme2 = res.data.data[0];
      } else {
        this.$message.error(res.data.msg);
      }
    });
  },
  watch: {
    "$store.state.language": function () {
      if (this.$store.state.language.type == 1) {
        this.configData = config.assembly.chinese;
      } else {
        this.configData = config.assembly.english;
      }
    },
  },
  methods: {
    play(e){
      var className = e.currentTarget.className;
      var videoBox = document.querySelectorAll('.contentVideo')
      for (var i = 1; i < videoBox.length + 1; i++) {
        if (className === videoBox[i - 1].className) {
        //console.log('播放视频不做处理');
        } else {
        //console.log('暂停其他正在播放的视频');
        videoBox[i - 1].pause();
        }
      }
    },
    videoEnd1() {
      let myVideo = document.querySelector(".contentVideo1");
      myVideo.currentTime = 0.5;
    },
    videoEnd2() {
      let myVideo = document.querySelector(".contentVideo2");
      myVideo.currentTime = 0.5;
    },
    imgLoad() {
      this.height = document.querySelector(".typeBox img").height;
    },
    videoEnd() {
      let myVideo = document.querySelector(".contentVideo");
      myVideo.currentTime = 0.5;
    },
    change5(Current5) {
      this.Current5 = Current5;
    },
    //手机滑动切换
    touchstartFn5(e) {
      this.startMove5 = e.changedTouches[0].clientX;
    },
    touchendFn5(e) {
      if (e.changedTouches[0].clientX - this.startMove5 > 50) {
        this.changePage5(-1);
      } else if (e.changedTouches[0].clientX - this.startMove5 < -50) {
        this.changePage5(1);
      }
    },
    changePage5(page) {
      if (page > 0) {
        this.$refs.carouselParter5.next();
      } else {
        this.$refs.carouselParter5.prev();
      }
    },
    goAudience() {
      this.$router.push("/Maudience");
    },
    change1(Current1) {
      this.Current1 = Current1;
    },
    change2(Current2) {
      this.Current2 = Current2;
    },
    change3(Current3) {
      this.Current3 = Current3;
    },
    change4(Current4) {
      this.Current4 = Current4;
    },
    //手机滑动切换
    touchstartFn4(e) {
      this.startMove4 = e.changedTouches[0].clientX;
    },
    touchendFn4(e) {
      if (e.changedTouches[0].clientX - this.startMove4 > 50) {
        this.changePage4(-1);
      } else if (e.changedTouches[0].clientX - this.startMove4 < -50) {
        this.changePage4(1);
      }
    },
    changePage4(page) {
      if (page > 0) {
        this.$refs.carouselParter4.next();
      } else {
        this.$refs.carouselParter4.prev();
      }
    },

    //手机滑动切换
    touchstartFn3(e) {
      this.startMove3 = e.changedTouches[0].clientX;
    },
    touchendFn3(e) {
      if (e.changedTouches[0].clientX - this.startMove3 > 50) {
        this.changePage3(-1);
      } else if (e.changedTouches[0].clientX - this.startMove3 < -50) {
        this.changePage3(1);
      }
    },
    changePage3(page) {
      if (page > 0) {
        this.$refs.carouselMap.next();
      } else {
        this.$refs.carouselMap.prev();
      }
    },
    //手机滑动切换
    touchstartFn2(e) {
      this.startMove2 = e.changedTouches[0].clientX;
    },
    touchendFn2(e) {
      if (e.changedTouches[0].clientX - this.startMove2 > 50) {
        this.changePage2(-1);
      } else if (e.changedTouches[0].clientX - this.startMove2 < -50) {
        this.changePage2(1);
      }
    },
    changePage2(page) {
      if (page > 0) {
        this.$refs.carouselParter2.next();
      } else {
        this.$refs.carouselParter2.prev();
      }
    },
    changePage(page) {
      if (page > 0) {
        this.$refs.carousel.next();
      } else {
        this.$refs.carousel.prev();
      }
    },
    //手机滑动切换
    touchstartFn(e) {
      this.startMove = e.changedTouches[0].clientX;
    },
    touchendFn(e) {
      if (e.changedTouches[0].clientX - this.startMove > 50) {
        this.changePage(-1);
      } else if (e.changedTouches[0].clientX - this.startMove < -50) {
        this.changePage(1);
      }
    },
    //轮播图倒计时功能
    countDownTimeFn() {
      if (this.countDownTime.time <= 0) return;
      var time = this.countDownTime.time - 1;

      let daydiff = 24 * 60 * 60;
      let hoursdiff = 60 * 60;
      let minutesdiff = 60;
      let d = Math.floor(time / daydiff); //天数
      let h = Math.floor((time - d * daydiff) / hoursdiff);
      let m = Math.floor((time - d * daydiff - h * hoursdiff) / minutesdiff);
      let s = Math.floor(time - d * daydiff - h * hoursdiff - m * minutesdiff);
      if (d >= 100) {
        this.countDownTime.day1 = Math.floor((d - 100) / 10);
        this.countDownTime.day2 = (d - 100) % 10;
      } else if (d < 10) {
        this.countDownTime.day1 = 0;
        this.countDownTime.day2 = d;
      } else {
        this.countDownTime.day1 = Math.floor(d / 10);
        this.countDownTime.day2 = d % 10;
      }
      if (h < 10) {
        this.countDownTime.hour1 = 0;
        this.countDownTime.hour2 = h;
      } else {
        this.countDownTime.hour1 = Math.floor(h / 10);
        this.countDownTime.hour2 = h % 10;
      }
      if (m < 10) {
        this.countDownTime.min1 = 0;
        this.countDownTime.min2 = m;
      } else {
        this.countDownTime.min1 = Math.floor(m / 10);
        this.countDownTime.min2 = m % 10;
      }
      if (s < 10) {
        this.countDownTime.sec1 = 0;
        this.countDownTime.sec2 = s;
      } else {
        this.countDownTime.sec1 = Math.floor(s / 10);
        this.countDownTime.sec2 = s % 10;
      }
      if (Math.floor(s % 10) == 9) {
        this.countDownTime.secType1 = true;
      }
      if (s == 59) {
        this.countDownTime.minType2 = true;
        if (Math.floor(m % 10) == 9) {
          this.countDownTime.minType1 = true;
        }
      }
      this.countDownTime.secType2 = true;
      setTimeout(() => {
        this.copyTimeFn();
      }, 500);
      this.countDownTime.time = time;
      // console.log(d , h , m , s)
    },
    //旋转翻页倒计时
    copyTimeFn() {
      this.countDownTime.secType1 = false;
      this.countDownTime.secType2 = false;
      this.countDownTime.minType1 = false;
      this.countDownTime.minType2 = false;
      this.countDownTime.hourType1 = false;
      this.countDownTime.hourType2 = false;
      this.countDownTime.dayType1 = false;
      this.countDownTime.dayType2 = false;
      var timeCopy = this.countDownTime.time;
      let daydiff = 24 * 60 * 60;
      let hoursdiff = 60 * 60;
      let minutesdiff = 60;
      let day = Math.floor(timeCopy / daydiff); //天数
      let hour = Math.floor((timeCopy - day * daydiff) / hoursdiff);
      let min = Math.floor(
        (timeCopy - day * daydiff - hour * hoursdiff) / minutesdiff
      );
      let sec = Math.floor(
        timeCopy - day * daydiff - hour * hoursdiff - min * minutesdiff
      );
      if (day >= 100) {
        this.countDownTime.dayCopy1 = Math.floor((day - 100) / 10);
        this.countDownTime.dayCopy2 = (day - 100) % 10;
        this.countDownTime.hundred = true;
      } else if (day < 10) {
        this.countDownTime.dayCopy1 = 0;
        this.countDownTime.dayCopy2 = day;
      } else {
        this.countDownTime.dayCopy1 = Math.floor(day / 10);
        this.countDownTime.dayCopy2 = day % 10;
      }
      if (hour < 10) {
        this.countDownTime.hourCopy1 = 0;
        this.countDownTime.hourCopy2 = hour;
      } else {
        this.countDownTime.hourCopy1 = Math.floor(hour / 10);
        this.countDownTime.hourCopy2 = hour % 10;
      }
      if (min < 10) {
        this.countDownTime.minCopy1 = 0;
        this.countDownTime.minCopy2 = min;
      } else {
        this.countDownTime.minCopy1 = Math.floor(min / 10);
        this.countDownTime.minCopy2 = min % 10;
      }
      if (sec < 10) {
        this.countDownTime.secCopy1 = 0;
        this.countDownTime.secCopy2 = sec;
      } else {
        this.countDownTime.secCopy1 = Math.floor(sec / 10);
        this.countDownTime.secCopy2 = sec % 10;
      }
    },
    //委员会主题查询
    getCommitteeList() {
      committee().then((res) => {
        if (res.data.code === "10000") {
          res.data.data.theme_list.forEach((item) => {
            item.theme_name = JSON.parse(item.theme_name);
          });
          res.data.data.expenseDetail = res.data.data.expense_detail.split(" ");
          this.CommitteeList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //早鸟价倒计时
    ebPriceDeferFn() {
      var time = this.eb_price_time.time;

      let daydiff = 24 * 60 * 60;
      let hoursdiff = 60 * 60;
      let minutesdiff = 60;
      let day = Math.floor(time / daydiff); //天数
      let hour = Math.floor((time - day * daydiff) / hoursdiff);
      let min = Math.floor(
        (time - day * daydiff - hour * hoursdiff) / minutesdiff
      );
      let sec = Math.floor(
        time - day * daydiff - hour * hoursdiff - min * minutesdiff
      );
      if (day < 10) {
        this.eb_price_time.day = "0" + day;
      } else {
        this.eb_price_time.day = day;
      }
      if (hour < 10) {
        this.eb_price_time.hour = "0" + hour;
      } else {
        this.eb_price_time.hour = hour;
      }
      if (min < 10) {
        this.eb_price_time.min = "0" + min;
      } else {
        this.eb_price_time.min = min;
      }
      if (sec < 10) {
        this.eb_price_time.sec = "0" + sec;
      } else {
        this.eb_price_time.sec = sec;
      }
      this.eb_price_time.time -= 1;
    },
    //酒店列表
    getHotelList() {
      hotel().then((res) => {
        if (res.data.code === "10000") {
          res.data.data.forEach((item) => {
            item.introduceList = item.introduce.split(" ");
          });
          this.HotelList = res.data.data[0];
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.Massembly {
  background: #1c1c1c;
  text-align: center;
  padding-bottom: 50px;
  .joinContent {
    margin-top: 48px;
	.stepWrapper{
		width: 331px;
		height: 247px;
		background: #333333;
		border-radius: 8px;
		margin: 24px auto 0;
		overflow: hidden;
		.stepList{
			margin-left: 75px;
			.lineCut{
				width: 2px;
				height: 13px;
				background: #fff;
				margin: 8px;
			}
			.lineListBox{
				display: flex;
				align-items: center;
				font-size: 18px;
				font-family: YouSheBiaoTiHei;
				color: #3DA6D5;
				line-height: 23px;
				img{
					width: 20px;
					height: 20px;
					margin-right: 12px;
				}
			}
		}
		.name{
			font-size: 18px;
			font-family: YouSheBiaoTiHei;
			color: #FFFFFF;
			line-height: 23px;
			margin: 16px auto 16px;
		}
	}
	.codeBox{
		width: 331px;
		height: 182px;
		background: #333333;
		border-radius: 8px;
		margin: 14px auto 0;
		display: flex;
		align-items: center;
		justify-content: center;
		img{
			width: 267px;
			height: 150px;
		}
	}
    .title {
      font-size: 24px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 31px;
    }
    .type {
      margin-top: 16px;
      .typeBox {
        width: 331px;
        height: 232px;
        position: relative;
        overflow: hidden;
        margin: 24px auto 0;
        img {
          width: 100%;
          height: 100%;
        }
        .titleSpan {
          padding: 0 14px;
          height: 50px;
          background: rgba(56, 56, 56, 0.2);
          border: 1px solid #ffffff;
          font-size: 32px;
          font-family: YouSheBiaoTiHei;
          color: #ffffff;
          line-height: 48px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 8px;
        }
      }
      .typeBox:active {
        img {
          transform: scale(1.1);
          transition: 0.2s;
        }
        .model {
          color: #fff;
        }
      }
      .typeBox:nth-of-type(1) {
        margin-top: 0;
      }
    }
  }
  .register {
    width: 331px;
    margin: 48px auto 0;
    .stepBox {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      .stepItem {
        width: 105px;
        height: 96px;
        background-image: url("./../../assets/img/step.png");
        background-size: 100% 100%;
        overflow: hidden;
        text-align: left;
        .stepinfo {
          font-size: 12px;
          font-family: AlibabaPuHuiTi_2_55_Regular;
          font-weight: 400;
          color: #ffffff;
          line-height: 14px;
          margin-left: 8px;
          width: 92px;
        }
        .steptitle {
          margin: 45px 0 4px 8px;
          font-size: 16px;
          font-family: YouSheBiaoTiHei;
          color: #ffffff;
          line-height: 21px;
          .color {
            color: #3da6d5;
          }
        }
      }
      .stepItem:nth-of-type(2) {
        margin: 0 8px;
      }
    }
    .title {
      font-size: 24px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 31px;
      text-align: center;
    }
  }
  .partner {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .partnerBox {
      width: 100%;
      margin-top: 7px;
      position: relative;
      height: 232px;
      .content {
        height: 100%;
        overflow: hidden;
      }
      .carousel {
        width: 331px;
        height: 100%;
        margin: 0 auto 0;
        /deep/ .el-carousel__container {
          height: 100%;
        }
      }
      .partnerItem {
        width: 331px;
        height: 100%;
        background: #000000;
        float: left;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }
    .title {
      margin-top: 30px;
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 26px;
    }
  }
  .speaker {
    margin-top: 48px;
    .place {
      margin-top: 40px;
      .mapContent {
        position: relative;
      }
      .placeTip {
        font-size: 12px;
        font-family: AlibabaPuHuiTi_2_55_Regular;
        color: #ffffff;
        line-height: 17px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-top: 46px;
        img {
          width: 12px;
          height: 18px;
          margin-right: 8px;
        }
      }
      .carouselMap {
        width: 337px;
        height: 209px;
        margin: 16px auto 0;
        /deep/ .el-carousel__container {
          width: 331px;
          height: 100%;
          margin: 0 auto;
        }
      }
      .content {
        height: 100%;
        overflow: hidden;
        .img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
      .title {
        font-size: 26px;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
        line-height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 27px;
          height: 26px;
          margin-left: 10px;
        }
      }
    }
    .schedule {
      width: 331px;
      margin: 48px auto 0;
      overflow: hidden;
      .content {
        margin-top: 24px;
        display: flex;
        .timeBox {
          .time {
            .timelist {
              .lineCut {
                width: 2px;
                height: 13px;
                opacity: 0.6;
                background: #fff;
                margin-top: 10px;
                margin-left: 9px;
                margin-bottom: 8px;
              }
              .listBox {
                display: flex;
                align-items: center;
                .stepName {
                  font-size: 14px;
                  font-family: "AlibabaPuHuiTi_2_55_Regular";
                  font-weight: 400;
                  color: #ffffff;
                  line-height: 20px;
                }
              }
              .clock {
                font-size: 18px;
                font-family: YouSheBiaoTiHei;
                color: #3da6d5;
                line-height: 23px;
                display: flex;
                align-items: center;
                width: 220px;
                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 24px;
                }
              }
            }
          }
          .day {
            font-size: 28px;
            font-family: YouSheBiaoTiHei;
            color: #3da6d5;
            line-height: 36px;
          }
        }
        .second {
          margin-top: 119px;
        }
      }
      .title {
        font-size: 24px;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
        line-height: 31px;
        text-align: center;
      }
    }
    .list {
      width: 331px;
      margin: 54px auto 0;
      img {
        width: 100%;
        height: 1038px;
        border-radius: 8px;
      }
    }
    .wrapper {
      height: 221px;
      position: relative;
      width: 331px;
      margin: 24px auto 0;
      /deep/ .el-carousel__container {
        height: 100%;
        width: 331px;
      }
      .carousel {
        height: 100%;
        width: 100%;
      }
      .speakerBox {
        width: 100%;
        height: 100%;
        // background: #fff;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }
    .titleName {
      font-size: 24px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .letter {
    width: 331px;
    margin: 48px auto 0;
    .title {
      font-size: 24px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 31px;
      text-align: center;
    }
    img {
      width: 331px;
      height: 428px;
      margin-top: 16px;
    }
  }
  .theme {
    width: 331px;
    margin: 40px auto 0;
    .title {
      font-size: 22px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 29px;
      text-align: center;
    }
    .color {
      color: #3da6d5;
    }
    .content {
      width: 331px;
      height: 186px;
      border-radius: 8px;
      margin: 18px auto 0;
      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
      video {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
  }
  .info {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 43px;
    .box1 {
      display: flex;
      align-items: center;
      .img1 {
        width: 151px;
        height: 33px;
      }
    }
    .type-name {
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 26px;
      margin-top: 22px;
    }
    .type-name2 {
      font-size: 14px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 18px;
      margin-top: 10px;
    }
    .type-name3 {
      font-size: 14px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 18px;
    }
    .type-name4 {
      font-size: 18px;
      font-family: YouSheBiaoTiHei;
      color: #3da6d5;
      line-height: 23px;
      margin-top: 12px;
    }
  }
  .MvideoBox {
    // background-image: url('./../../assets/img/assemblyBanner.png');
    video {
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
    }
  }
  .parterTitle {
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    color: #ffffff;
    line-height: 31px;
    margin-top: 36px;
  }
}

.money {
  width: 331px;
  margin: 48px auto 0;
  .secondLine {
    margin-top: 12px;
  }
  .moneyBox {
    width: 100%;
    height: 861px;
    background-image: url("https://www.betterworldmun.com/uploads/wxapp/img/Homemoney1.png");
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
    margin-top: 16px;
  }
  .name {
    font-size: 20px;
    font-family: YouSheBiaoTiHei;
    color: #ffffff;
    line-height: 32px;
    text-align: right;
    margin-top: 31px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .year {
    display: block;
    width: 166px;
    font-size: 15px;
    font-family: YouSheBiaoTiHei;
    color: #ffffff;
    line-height: 24px;
  }
  .moneyContent {
    margin-top: 272px;
  }
  .count {
    text-align: center;
  }
  .number {
    font-size: 28px;
    font-family: YouSheBiaoTiHei;
    color: #3da6d5;
    line-height: 36px;
  }
  .oldNum {
    font-size: 14px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #d1d1d1;
    line-height: 20px;
    text-decoration: line-through;
    text-align: center;
  }
  .moneyDesc {
    margin: 0 auto;
    width: 164px;
    font-size: 14px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #d1d1d1;
    line-height: 20px;
    text-align: center;
  }
  .people {
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    color: #ffffff;
    line-height: 31px;
    margin-left: 16px;
  }
  .moneyContent .desc {
    font-size: 14px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #666666;
    line-height: 20px;
    text-align: center;
    margin: 0 16px;
  }
  .leftinfo {
    width: 276px;
    margin: 16px auto 0;
    font-size: 14px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #d1d1d1;
    line-height: 20px;
    text-align: left;
  }
  .backdesc {
    width: 299px;
    height: 196px;
    background: #1c1c1c;
    border-radius: 8px;
    font-size: 14px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #d1d1d1;
    line-height: 20px;
    margin: 24px auto 0;
    padding: 13px;
    text-align: left;
  }
  .title {
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    color: #ffffff;
    line-height: 31px;
  }
  .backTips {
    margin-top: 12px;
  }
  .timeBox {
    width: 299px;
    height: 36px;
    background: #1c1c1c;
    border-radius: 8px;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 15px;
    font-family: YouSheBiaoTiHei;
    color: #ffffff;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .timeNum {
    width: 28px;
    height: 28px;
    background: #333333;
    border-radius: 4px;
    font-size: 15px;
    font-family: YouSheBiaoTiHei;
    color: #3da6d5;
    line-height: 28px;
    text-align: center;
    margin-right: 4px;
  }
  .timeWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
  }
  .timeWrapper:nth-of-type(1) {
    margin-left: 0;
  }
}

.live {
  width: 331px;
  margin: 48px auto 0;
  .title {
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    color: #ffffff;
    line-height: 31px;
  }
  .desc {
    font-size: 12px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #ffffff;
    line-height: 17px;
    margin-top: 16px;
    text-align: center;
  }
  .imgs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .imgBox {
    width: 160px;
    height: 191px;
    background: #636363;
    border-radius: 8px;
    margin-top: 16px;
  }

  .circle {
    width: 9px;
    height: 9px;
    background: #ffffff;
    border-radius: 50%;
    margin-top: 8px;
  }
  .text {
    display: flex;
    margin-top: 30px;
    span {
      font-size: 14px;
      font-family: AlibabaPuHuiTi_2_55_Regular;
      color: #d1d1d1;
      line-height: 24px;
      margin-left: 8px;
      width: 314px;
    }
  }
  .text2 {
    margin-top: 20px;
  }
  .liveContent {
    width: 331px;
    height: 814px;
    background: #333333;
    border-radius: 8px;
    margin: 16px auto 0;
    overflow: hidden;
    position: relative;
  }
  .liveBox {
    width: 299px;
    height: 468px;
    background: #1c1c1c;
    border-radius: 8px;
    margin: 16px auto 0;
    overflow: hidden;
  }
  .liveTitle {
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin: 12px auto 0;
  }
  .liveImg {
    width: 299px;
    height: 224px;
  }
  .livedesc {
    margin: 12px auto 0;
    width: 299px;
    height: 258px;
    padding: 12px;
    background: #1c1c1c;
    border-radius: 8px;
    font-size: 14px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #fff;
    line-height: 20px;
    text-align: left;
  }
  .label {
    display: flex;
    margin-top: 12px;
  }
  .payNum {
    font-size: 32px;
    font-family: YouSheBiaoTiHei;
    color: #3da6d5;
    line-height: 45px;
    text-align: center;
  }
  .backTips {
    margin-top: 12px;
  }

  .livetime {
    width: 315px;
    height: 36px;
    background: #1c1c1c;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px auto 0;
  }

  .livetime .day {
    font-size: 16px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #fff;
    line-height: 22px;
  }
  .livetime .countDate {
    width: 33px;
    height: 18px;
    background: #1c1c1c;
    border-radius: 9px;
    border: 1px solid #979797;
    text-align: center;
    position: relative;
    margin: 0 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #fff;
    line-height: 16px;
  }
  .oldNum {
    font-size: 14px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #d1d1d1;
    line-height: 20px;
    text-decoration: line-through;
    text-align: center;
  }
  .moneyDesc {
    margin: 0 auto;
    width: 164px;
    font-size: 14px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #d1d1d1;
    line-height: 20px;
    text-align: center;
  }
  .tips {
    margin: 8px 12px 0;
    width: 299px;
  }
  .tipBox {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    color: #fff;
    line-height: 20px;
    margin-top: 16px;
  }
  .tipBox:nth-of-type(1) {
    margin-top: 0;
  }
  .tipBox .circle {
    width: 9px;
    height: 9px;
    background: #fff;
    margin-right: 5px;
    margin-top: 0;
  }
  .timeBox {
    width: 299px;
    height: 36px;
    background: #1c1c1c;
    border-radius: 8px;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 15px;
    font-family: YouSheBiaoTiHei;
    color: #ffffff;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .timeNum {
    width: 28px;
    height: 28px;
    background: #333333;
    border-radius: 4px;
    font-size: 15px;
    font-family: YouSheBiaoTiHei;
    color: #3da6d5;
    line-height: 28px;
    text-align: center;
    margin-right: 4px;
  }
  .timeWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
  }
  .timeWrapper:nth-of-type(1) {
    margin-left: 0;
  }
}
.livetimeBox {
  width: 315px;
  height: 36px;
  background: #1c1c1c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px auto 0;
}

.livetimeBox .day {
  font-size: 16px;
  font-family: AlibabaPuHuiTi_2_55_Regular;
  color: #fff;
  line-height: 22px;
}
.livetimeBox .countDate {
  width: 33px;
  height: 18px;
  background: #1c1c1c;
  border-radius: 9px;
  border: 1px solid #979797;
  text-align: center;
  position: relative;
  margin: 0 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: AlibabaPuHuiTi_2_55_Regular;
  color: #fff;
  line-height: 16px;
}
.countDate .line {
  position: absolute;
  width: 3px;
  height: 1px;
  border: 1px solid #979797;
  top: 50%;
  transform: translateY(-50%);
}
.countDate .line1 {
  left: -4px;
}
.countDate .line2 {
  right: -4px;
}

.letterinfo {
  margin: 48px auto 0;
  width: 331px;
  .name {
    font-size: 12px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    font-weight: 400;
    color: #ffffff;
    line-height: 21px;
    text-align: right;
    span {
      margin-top: 4px;
      display: block;
    }
    .name1 {
      font-size: 14px;
      margin-top: 24px;
    }
  }
  .textBoxContent {
    margin-top: 24px;
    .textContent {
      .desc {
        font-size: 14px;
        font-family: AlibabaPuHuiTi_2_55_Regular;
        font-weight: 400;
        color: #aaa;
        line-height: 20px;
        margin-top: 24px;
        position: relative;
        text-align: left;
        .letterLeft {
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 18px;
        }
        .letterLeftSpan {
          text-indent: 30px;
        }
      }
      .desc:nth-of-type(1) {
        font-size: 14px;
        color: #fff;
      }
    }
    .letterBottom {
      margin-top: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .Bottomname {
        width: 174px;
        font-size: 14px;
        font-family: YouSheBiaoTiHei;
        color: #3da6d5;
        line-height: 18px;
      }
      .letterRight {
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        img {
          width: 20px;
          height: 18px;
        }
      }
      .line {
        width: 100%;
        height: 4px;
        background: #3da6d5;
        margin-top: 4px;
        border-radius: 4px;
      }
    }
  }
  .infoHead {
    display: flex;
    align-items: center;
    flex-direction: column;
    .text1 {
      font-size: 16px;
      font-family: YouSheBiaoTiHei;
      color: #3da6d5;
      line-height: 21px;
    }
    .text2 {
      font-size: 16px;
      font-family: YouSheBiaoTiHei;
      color: #3da6d5;
      line-height: 21px;
      margin-top: 6px;
    }
    .text3 {
      font-size: 16px;
      font-family: YouSheBiaoTiHei;
      color: #3da6d5;
      line-height: 21px;
      margin-top: 6px;
    }
    img {
      width: 46px;
      height: 38px;
      margin-top: 22px;
    }
  }
}
</style>