var config = {
    join: {
        chinese: {
            root1: '委员会',
            root2: '参会准备',
            root3: '衣食住行',
            root4: '城市探索',
        },
        english: {
            root1: '',
            root2: '',
            root3: '',
            root4: '',
        }
    },
    home: {
        chinese: {
            root1: '关于',
            root2: '注册',
            root3: '大会',
            root4: '视频',
            root5: '联系我们',
        },
        english: {
            root1: 'ABOUT',
            root2: 'REGISTRATION',
            root3: 'CONFERENCE',
            root4: 'VIDEOS',
            root5: 'CONTACT',
        }
    },
    beijing: {
        chinese: {
            time1: '天',
            time2: '时',
            time3: '分',
            time4: '秒',
            root1: '关于',
            root2: '注册',
            root3: '大会',
            root4: '视频',
            title1: '注册报名',
            rootdec1: 'BWMUN介绍+BWMUN使命+为什么选择BWMUN',
            rootdec2: '个人注册+团队注册+主席团注册+志愿者注册',
            rootdec3: '委员会+参会准备+衣食住行+城市探索',
            rootdec4: '嘉宾欢迎视频+BWMUN大会视频',
            letterTitle: 'Global Elites MUN 秘书长致信',
            text: [{
                    desc: '尊敬的各位代表、指导教师： ',
                    top: false,
                    right: false,
                },
                {
                    desc: '我们特此诚挚地邀请您莅临并参加BetterWorld全球青少年模拟联合国大会·2025夏季会议。会议将于2025年7月29日-8月1日在上海举办，本次大会将成为国际会议中规模最大、国际化程度最高的会议之一。',
                    top: false,
                    right: false,
                },
                {
                    desc: '当今国际社会面临着许多前所未有的全球性挑战，气候变化、国家冲突、贸易对抗、种族歧视、人口激增、粮食危机、网络安全等等。各国只有通过多边合作，达成共同维护长期和平与致力于经济发展的共识，才能应对当下的诸多挑战。BetterWorld全球青少年模拟联合国大会也同样如此，我们汇集了来自世界各地具有不同背景的代表，大家通过讨论、磋商、谈判、合作，解决国际关系中最紧迫的问题。',
                    top: false,
                    right: false,
                },
                {
                    desc: 'BetterWorld全球青少年模拟联合国大会的规模和国际性质使其成为超越委员会会议范围的学习机会，在四天的时间里，BetterWorldMUN旨在培养代表们的批判性思考，塑造代表们的领导力和团队合作能力，运用谈判的艺术来解决争议。',
                    top: false,
                    right: false,
                },
                {
                    desc: '现在，会议报名通道已经开启，申请信息以及其他重要日期可以在我们的网站上找到。组委会所有工作人员将认真履行职责，做好会前及会中的各项工作，为大家带来一场国际顶尖水准的模拟联合国大会！',
                    top: false,
                    right: false,
                },
                {
                    desc: '如果您遇到任何疑问，请随时与我们联系，期待与您相见！',
                    top: false,
                    right: false,
                },
            ],
            textName: {
                name1: 'BetterWorldMUN',
                name2: '全球青少年模拟联合国大会',
                name3: '组委会  ',
                name4: '2025年1月',
            }
        },
        english: {
            time1: 'Days',
            time2: 'Hours',
            time3: 'Minutes',
            time4: 'Seconds',
            root1: 'ABOUT',
            root2: 'REGISTRATION',
            root3: 'CONFERENCE',
            root4: 'VIDEOS',
            title1: 'Registration',
            rootdec1: 'What+Mission+Why',
            rootdec2: 'Delegate+Delegation+Chairs+Volunteers',
            rootdec3: 'xxx+xxx+xxx+xxx',
            rootdec4: "Guests' and Charis' Welcome Videos",
            letterTitle: '',
            text: [{
                    desc: 'A Letter from the Secretary-General:',
                    top: false,
                    right: false,
                },
                {
                    desc: 'Dear Delegates and Faculty Advisers,',
                    top: false,
                    right: false,
                },
                {
                    desc: 'It is with absolute pleasure that I invite you to join us for the xxx Model United Nations (xxMUN). We are so excited to welcome you to the Boston Marriott Copley Place from May 16 to May 19, 2023. Our conference prides itself in being one of the most unique conferences on the collegiate circuit. For nearly seven decades, xxMUN has been an annual forum for hundreds of college students from over 40 countries. Every year, delegates from around the world come to xxxx to discuss pressing international issues and defend their positions passionately. They learn how to negotiate with other people and collaborate with like-minded representatives. They work together on policy proposals and detailed programs in order to solve important global problems on topics ranging from drone technology to territorial sovereignty. ',
                    top: true,
                    right: false,
                },
                {
                    desc: 'However, xxxMUN is so much more than just the debate that happens in committees. Delegates at our conferences build friendships and make connections with people from diverse backgrounds. These relationships often go past the four days we spend at conference together. Staffed every year by over 150 undergraduates from xxx College, xxxMUN remains the oldest, largest, and most prestigious collegiate conference of its kind, and our team is looking forward to continuing this legacy by hosting another successful xxxMUN. ',
                    top: true,
                    right: false,
                },
                {
                    desc: 'This year’s conference will feature twenty-five unique committees, each led by a team of passionate directors and assistant directors. Through our General Assemblies, Economic and Social Councils and Regional Bodies, and Specialized Agencies, delegates will have access to a broad range of committee forms and topics. From this wide range of options, delegates have the opportunity to find a committee that fits their interests and matches their preferred style of debate. ',
                    top: true,
                    right: false,
                },
                {
                    desc: 'To apply for xxxMUN 2023, you can register with our online platform MUNBase, linked in the navigation bar of this website. Registration opens at the end of May. Before registering, I strongly encourage perusing our website to learn more about the conference. This website contains information about the Secretariat, registration deadlines and fees, conference policies, and committees. If you have any other questions please do not hesitate to reach out to our Administration team at xxx@gmail.com.',
                    top: true,
                    right: false,
                },
                {
                    desc: 'The Secretariat and I cannot wait to welcome you to xxxMUN in beijing this May!',
                    top: true,
                    right: false,
                },
                {
                    desc: 'Sincerely,',
                    top: false,
                    right: false,
                },
                {
                    desc: 'Hang Qin',
                    top: true,
                    right: true,
                },
                {
                    desc: 'Secretary-General',
                    top: false,
                    right: true,
                },
                {
                    desc: 'XXX Model United Nations 2023',
                    top: false,
                    right: true,
                },
                {
                    desc: 'xxx@gmail.com',
                    top: false,
                    right: true,
                }
            ],
            textName: {
                name1: 'Hang Qin',
                name2: '秘书长',
                name3: '全球青年模拟联合国大会组委会 ',
                name4: '2023年7月',
            }
        }
    },
    contact: {
        chinese: {
            title1: '联系我们',
            title2: '你的姓名',
            title3: '你的微信号',
            title4: '常用邮箱',
            title5: '手机号',
            title6: '清输入手机号',
            title7: '请输入验证码',
            title8: '主题',
            title9: '信息',
        },
        english: {
            title1: 'Contact Us',
            title2: 'Name',
            title3: 'WeChat',
            title4: 'Email',
            title5: 'Mobile Phone Number',
            title6: '',
            title7: '',
            title8: 'Topic',
            title9: 'Message',
        }
    },
    assembly: {
        chinese: {
            parter1: '战略合作伙伴',
            parter2: '重要合作伙伴',
            parter3: '合作伙伴',
            text: [{
                    desc: '尊敬的各位代表、指导教师： ',
                    top: false,
                    right: false,
                },
                {
                    desc: '我们特此诚挚地邀请您莅临并参加BetterWorld全球青少年模拟联合国大会·2025夏季会议。会议将于2025年7月29日-8月1日在上海举办，本次大会将成为国际会议中规模最大、国际化程度最高的会议之一。',
                    top: false,
                    right: false,
                },
                {
                    desc: '当今国际社会面临着许多前所未有的全球性挑战，气候变化、国家冲突、贸易对抗、种族歧视、人口激增、粮食危机、网络安全等等。各国只有通过多边合作，达成共同维护长期和平与致力于经济发展的共识，才能应对当下的诸多挑战。BetterWorld全球青少年模拟联合国大会也同样如此，我们汇集了来自世界各地具有不同背景的代表，大家通过讨论、磋商、谈判、合作，解决国际关系中最紧迫的问题。',
                    top: false,
                    right: false,
                },
                {
                    desc: 'BetterWorld全球青少年模拟联合国大会的规模和国际性质使其成为超越委员会会议范围的学习机会，在四天的时间里，BetterWorldMUN旨在培养代表们的批判性思考，塑造代表们的领导力和团队合作能力，运用谈判的艺术来解决争议。',
                    top: false,
                    right: false,
                },
                {
                    desc: '现在，会议报名通道已经开启，申请信息以及其他重要日期可以在我们的网站上找到。组委会所有工作人员将认真履行职责，做好会前及会中的各项工作，为大家带来一场国际顶尖水准的模拟联合国大会！',
                    top: false,
                    right: false,
                },
                {
                    desc: '如果您遇到任何疑问，请随时与我们联系，期待与您相见！',
                    top: false,
                    right: false,
                },
            ],
            textName: {
                name1: 'BetterWorldMUN',
                name2: '全球青少年模拟联合国大会',
                name3: '组委会  ',
                name4: '2025年1月',
            }
        },
        english: {
            parter1: 'Strategic Partnership',
            parter2: 'Advanced Partnership',
            parter3: 'General Partnership',
            text: [{
                    desc: 'A Letter from the Secretary-General:',
                    top: false,
                    right: false,
                },
                {
                    desc: 'Dear Delegates and Faculty Advisers,',
                    top: false,
                    right: false,
                },
                {
                    desc: 'It is with absolute pleasure that I invite you to join us for the xxx Model United Nations (xxMUN). We are so excited to welcome you to the Boston Marriott Copley Place from May 16 to May 19, 2023. Our conference prides itself in being one of the most unique conferences on the collegiate circuit. For nearly seven decades, xxMUN has been an annual forum for hundreds of college students from over 40 countries. Every year, delegates from around the world come to xxxx to discuss pressing international issues and defend their positions passionately. They learn how to negotiate with other people and collaborate with like-minded representatives. They work together on policy proposals and detailed programs in order to solve important global problems on topics ranging from drone technology to territorial sovereignty. ',
                    top: true,
                    right: false,
                },
                {
                    desc: 'However, xxxMUN is so much more than just the debate that happens in committees. Delegates at our conferences build friendships and make connections with people from diverse backgrounds. These relationships often go past the four days we spend at conference together. Staffed every year by over 150 undergraduates from xxx College, xxxMUN remains the oldest, largest, and most prestigious collegiate conference of its kind, and our team is looking forward to continuing this legacy by hosting another successful xxxMUN. ',
                    top: true,
                    right: false,
                },
                {
                    desc: 'This year’s conference will feature twenty-five unique committees, each led by a team of passionate directors and assistant directors. Through our General Assemblies, Economic and Social Councils and Regional Bodies, and Specialized Agencies, delegates will have access to a broad range of committee forms and topics. From this wide range of options, delegates have the opportunity to find a committee that fits their interests and matches their preferred style of debate. ',
                    top: true,
                    right: false,
                },
                {
                    desc: 'To apply for xxxMUN 2023, you can register with our online platform MUNBase, linked in the navigation bar of this website. Registration opens at the end of May. Before registering, I strongly encourage perusing our website to learn more about the conference. This website contains information about the Secretariat, registration deadlines and fees, conference policies, and committees. If you have any other questions please do not hesitate to reach out to our Administration team at xxx@gmail.com.',
                    top: true,
                    right: false,
                },
                {
                    desc: 'The Secretariat and I cannot wait to welcome you to xxxMUN in beijing this May!',
                    top: true,
                    right: false,
                },
                {
                    desc: 'Sincerely,',
                    top: false,
                    right: false,
                },
                {
                    desc: 'Hang Qin',
                    top: true,
                    right: true,
                },
                {
                    desc: 'Secretary-General',
                    top: false,
                    right: true,
                },
                {
                    desc: 'XXX Model United Nations 2023',
                    top: false,
                    right: true,
                },
                {
                    desc: 'xxx@gmail.com',
                    top: false,
                    right: true,
                }
            ],
            textName: {
                name1: 'Hang Qin',
                name2: '秘书长',
                name3: '全球青年模拟联合国大会组委会 ',
                name4: '2023年7月',
            }
        }
    },
    weiyuan: {
        chinese: {
            type1: {
                title: '',
                list: [{
                        tip: '',
                        tagBox: [],
                        name: '',
                        desc: '',
                    },
                    {
                        tip: '',
                        tagBox: [],
                        name: '',
                        desc: '',
                    }
                ]
            },
            type2: {
                title: '',
                list: [{
                        tip: '',
                        tagBox: [],
                        name: '',
                        desc: '',
                    },
                    {
                        tip: '',
                        tagBox: [],
                        name: '',
                        desc: '',
                    }
                ]
            },
            type3: {
                title: '',
                list: [{
                        tip: '',
                        tagBox: [],
                        name: '',
                        desc: '',
                    },
                    {
                        tip: '',
                        tagBox: [],
                        name: '',
                        desc: '',
                    }
                ]
            }
        },
        english: {
            type1: {
                title: 'The General Assembly',
                list: [{
                        tip: 'Global Elites Model',
                        tagBox: ['主题：xxx', '背景文件'],
                        name: 'Chairman：Cecilia Wan',
                        desc: 'Welcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global',
                    },
                    {
                        tip: 'Global Elites Model',
                        tagBox: ['主题：xxx', '背景文件'],
                        name: 'Chairman：Cecilia Wan',
                        desc: 'Welcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global',
                    },
                ]
            },
            type2: {
                title: 'Economic and Social Council & Regional Bodies',
                list: [{
                        tip: 'Global Elites Model',
                        tagBox: ['主题：xxx', '背景文件'],
                        name: 'Chairman：Cecilia Wan',
                        desc: 'Welcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global',
                    },
                    {
                        tip: 'Global Elites Model',
                        tagBox: ['主题：xxx', '背景文件'],
                        name: 'Chairman：Cecilia Wan',
                        desc: 'Welcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global',
                    },
                    {
                        tip: 'Global Elites Model',
                        tagBox: ['主题：xxx', '背景文件'],
                        name: 'Chairman：Cecilia Wan',
                        desc: 'Welcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global',
                    },
                ]
            },
            type3: {
                title: 'Specialized Agencies',
                list: [{
                        tip: 'Global Elites Model',
                        tagBox: ['主题：xxx', '背景文件'],
                        name: 'Chairman：Cecilia Wan',
                        desc: 'Welcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global',
                    },
                    {
                        tip: 'Global Elites Model',
                        tagBox: ['主题：xxx', '背景文件'],
                        name: 'Chairman：Cecilia Wan',
                        desc: 'Welcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global Elites Model United NationsWelcome to Global',
                    },
                ]
            }
        }
    },
};
module.exports = {
    config: config
}